import React from "react";
import "./style.css";
function Loading(props) {
  const { custom } = props;
  return (
    <div className={`flex ${custom ? custom : "h-full justify-center items-center"}`}>
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;
