import React from "react";

function Button(props) {
  const { text, sub, onClick } = props;
  return (
    <div className="bg-rose-500 w-full md:w-fit rounded-full md:px-10 py-4  drop-shadow-xl mx-auto " onClick={onClick}>
      <div className="text-white text-xl font-semibold ">{text}</div>

      <span className="text-white text-lg font-light">{sub}</span>
    </div>
  );
}

export default Button;
