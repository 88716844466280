import React, { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useRef, useEffect } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import Loading from "./Loading/Loading";

export default function MyModal(props) {
  const { closeModal, openModal, isOpen } = props;
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [isOpen]);

  // if (isLoading) {
  //   return <Loading />;
  // }

  return (
    <>
      {/* <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button>
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[700px] h-[400px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <RiCloseCircleLine className="absolute right-2 top-2 hover:cursor-pointer hover:text-blue-500" size={20} onClick={closeModal} />
                  <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900 text-center">
                    Comment Épargner Sur Les Cryptos De Façon Simple, Sécurisé et ... 100% automatique
                  </Dialog.Title>
                  <Dialog.Title className="text-xs font-light leading-6 text-center text-gray-900">Entrez vos coordonnées ci-dessous pour accéder à la formation gratuite.</Dialog.Title>

                  {/* <div className="flex flex-col items-center mt-4">
                    <input class="w-2/3 py-4 rounded mb-2 border border-slate-300" type="text" id="name" name="prénom" placeholder=" Votre prénom ..."></input>
                    <input class="w-2/3 py-4 rounded mb-2 border border-slate-300" type="email" id="email" name="email" placeholder=" Votre adresse email ..."></input>
                    <input class="w-2/3 py-4 rounded mb-2 border border-slate-300" type="tel" id="phone" name="phone" placeholder=" Votre numéro de téléphone ..."></input>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 w-2/3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      J'accède à la formation maintenant{" "}
                    </button>
                    <div class="flex items-center text-xs justify-center space-x-1 mt-6">
                      <CiLock />
                      <div>Vos informations sont 100% sécurisées</div>
                    </div>
                  </div> */}
                  {isLoading && <Loading />}
                  <iframe id="frame" src="https://dashboard.mailerlite.com/forms/58176/61246876795536529/share" width="100%" height="300px"></iframe>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
