import React from "react";
import { FaCircleNotch } from "react-icons/fa";

function Explication(props) {
  const { children } = props;
  return (
    <div className=" text-justify line text-lg font-light mx-4 mb-6">
      <div className="  items-left relative">
        <FaCircleNotch className="mt-1 mr-1 text-blue-500 absolute left-0 " />
        <span className="align-left ml-6">{children}</span>
      </div>
    </div>
  );
}

export default Explication;
