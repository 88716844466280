import React, { useState, useRef, useEffect } from "react";
import { HiPlusSm, HiMinusSm } from "react-icons/hi";

function Accordion(props) {
  const { title, children, ...other } = props;
  const [isTextShown, setIsTextShown] = useState(false);
  const divRef = useRef(null);
  const handleTextShow = () => {
    setIsTextShown(!isTextShown);
  };

  return (
    <>
      <div className="flex items-center drop-shadow-xl bg-white py-4  hover:cursor-pointer text-lg font-bold rounded" onClick={handleTextShow} {...other}>
        <div className="flex-1 text-sm md:text-sm text-left ml-4">{title}</div>
        <div className="  w-10 ">{isTextShown ? <HiMinusSm size={30} /> : <HiPlusSm size={30} className="" />}</div>
      </div>
      <div className={`relative overflow-hidden transition-all max-h-0 duration-500 mt-5 ${isTextShown ? "max-h-screen mb-8" : ""}  `}>{children}</div>
    </>
  );
}

export default Accordion;
