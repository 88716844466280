import logo from "./logo.svg";
import { useEffect, useState } from "react";
import "./App.css";
import Button from "./components/Button";
import Steps from "./components/Steps";
import Explication from "./components/Explication";
import Logo from "./asset/images/Crypto-bulot.png";
import V1 from "./asset/images/v1.png";
import Trust from "./asset/images/trustPilot.png";
import Company from "./asset/images/company.png";
import V6 from "./asset/images/v6.png";
import V7 from "./asset/images/v7.png";
import V9 from "./asset/images/v9.png";
import V10 from "./asset/images/v10.png";
import Accordion from "./components/Accordion";
import MyModal from "./components/Modal";
import TrustBoxWidget from "./components/TrustBox";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        <div className='text-red-500'>Test</div>
      </header> */}
      <MyModal closeModal={closeModal} openModal={openModal} isOpen={isOpen} />
      <div className="h-auto py-4 bg-blue-700 text-white font-extrabold text-xl  p-2 justify-center">
        🛑 Formation gratuite JEUDI à 20H (Heure de France) ,{" "}
        <span className="pl-2 text-amber-500  p-2" style={{ textShadow: "0 0 30px #000" }}>
          {/* <span className="animate-ping opacity-75 absolute ">Un REPLAY sera disponible !</span> */}
          <span className="relative ">Un REPLAY sera disponible !</span>
        </span>
      </div>
      <div className="x-32 md:max-w-[1170px]  mx-auto">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center md:mx-4 p-2  md:space-y-0 ">
          <div>
            {/* <img src={require("./asset/images/Crypto-bulot.png")} /> */}
            <img src={Logo} className="w-[200px] h-[110px] mx-auto" />
          </div>

          <div className="hover:cursor-pointer">
            <Button text="Je veux la formation gratuite" onClick={openModal} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-start md:mx-8 ">
          <div className=" w-full md:w-1/2 md:mt-20  ">
            <div className="text-[30px] md:text-4xl  md:text-left font-bold md:leading-relaxed">
              Comment Épargner Sur Les Cryptos De Façon Simple, Sécurisé Et...<span className="text-blue-500">100% Automatique</span>
            </div>

            <div className="mt-2 text-lg md:text-left">Formation gratuite JEUDI à 20H (Heure de France)</div>
            <div className="hidden md:flex md:flex-col md:items-start">
              <div className="my-6 hover:cursor-pointer">
                <Button text="Je veux la formation gratuite" sub="Durée 1H30" onClick={openModal} />
              </div>

              {/* trustPilot */}
              <TrustBoxWidget />
              {/* <div className="mb-6 ">
                <img src={Trust} className="w-[300px] h-[72px] mx-auto " />
              </div> */}
            </div>
          </div>

          <div className=" w-full md:w-1/2 " data-aos="fade-up" data-aos-duration="700">
            <img src={V1} className=" mx-auto" />
          </div>
        </div>
        <div className="block md:hidden">
          <TrustBoxWidget />

          {/* <div className="mb-6">
            <img src={Trust} className="w-[300px] h-[72px] mx-auto " />
          </div> */}

          <div className="mb-8 hover:cursor-pointer">
            <Button text="Je veux la formation gratuite" sub="Durée 1H30" onClick={openModal} />
          </div>
        </div>

        <div className="mb-8 md:mx-20">
          {" "}
          <img src={Company} className=" mx-auto " />
        </div>

        <div className="flex flex-col md:flex-row pt-10 ">
          <div className="w-full md:w-1/2 md:m-4">
            <div className="text-2xl md:text-3xl md:text-left md:mt-4 md:px-8 font-bold">
              Dans cette formation <span className="text-blue-500"> vous allez apprendre :</span>
            </div>

            <div>
              <img src={V6} className=" mx-auto p-12 " />
            </div>
          </div>

          {/* steps */}
          <div className="flex-col space-y-5 w-full md:w-1/2">
            <Steps number={"01"} hasLigne data-aos="fade-up" data-aos-duration="600">
              <div className="text-center  md:text-justify text-lg font-light">
                <span className="font-semibold ">Une stratégie d’épargne crypto simple </span>
                rapide, fiable et efficace à appliquer pour investir en bon père de famille sur le long terme de façon sécurisée.
              </div>
            </Steps>
            <Steps number={"02"} hasLigne data-aos="fade-up" data-aos-duration="600">
              <div className="text-center  md:text-justify text-lg font-light">
                <span className="font-semibold">Pourquoi le marché des crypto va exploser </span>
                dans les tous prochains mois avec des perspectives de croissance historiques.{" "}
              </div>
            </Steps>
            <Steps number={"03"} hasLigne data-aos="fade-up" data-aos-duration="700">
              <div className="text-center  md:text-justify text-lg font-light">
                <span className="font-semibold">Les 4 techniques (cachées par les traders expérimentés) </span>
                qui ont permis à cette stratégie de faire x83 sur le capital sur 6 ans (Alors que le marché a fait X29). Ces techniques peuvent être appliquées sans rien y connaître dans le trading ou
                les cryptos.{" "}
              </div>
            </Steps>
            <Steps number={"04"} hasLigne data-aos="fade-up" data-aos-duration="600">
              <div className="text-center  md:text-justify text-lg font-light">
                <span className="font-semibold">La formule de calcul accessible à tous avec le plan d’action pas à pas </span>
                pour appliquer soi-même la méthode en très peu de temps 1x par mois.{" "}
              </div>
            </Steps>
            <Steps number={"05"} data-aos="fade-up" data-aos-duration="600">
              <div className="text-center  md:text-justify text-lg font-light">
                <span className="font-semibold">Un outil exclusif </span>
                pour profiter de cette stratégie de façon 100% automatique !{" "}
              </div>
            </Steps>
          </div>
        </div>

        <div className="mt-10 hover:cursor-pointer" data-aos="fade-up" data-aos-duration="600">
          <Button text="Je veux la formation gratuite" sub="Durée 1H30" onClick={openModal} />
        </div>

        <div className="my-12 p-4 md:m-20 bg-white drop-shadow-lg rounded-2xl" data-aos="zoom-out-up" data-aos-duration="1000">
          <div className="text-blue-700 font-bold text-xl">La performance de Crypto Bulot par rapport au marché des Cryptos</div>
          <img src={V7} className=" mx-auto " />
          <div className="my-4">
            <div className="flex items-center justify-center">
              <div className="w-4 h-2 bg-blue-500" />
              <div className="text-sm font-semibold text-slate-600 pl-2 ">Performance du marché des Cryptos (Sur la base du Top30) => Capital x29</div>
            </div>
            <div className="flex items-center justify-center">
              <div className="w-4 h-2 bg-amber-400" />
              <div className="text-sm font-semibold text-slate-600 pl-2">Performance de la stratégie Crypto Bulot => Capital x83</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row space-x-4 md:mx-6">
          <div className="w-full md:w-1/2" data-aos="fade-right" data-aos-duration="2000">
            <div className=" md:leading-normal font-bold">
              <div className="text-2xl md:text-3xl">Présenté par Florent Fouque</div>
              <div className="text-xl md:text-2xl text-blue-500"> CEO et Fondateur de Crypto Bulot</div>
            </div>

            <div className="mt-4">
              <img src={V9} className=" mx-auto " />
            </div>
          </div>

          {/* Explication */}
          <div className="w-full md:w-1/2 md:bg-white md:drop-shadow-2xl md:rounded-xl md:pt-16 md:px-10 md:space-y-10 " data-aos="fade-left" data-aos-duration="2000">
            <div>
              <Explication>
                <span className="font-semibold ">Florent FOUQUE est Auteur / Formateur / Editeur</span> sur les sciences de l’enrichissement. Il est l’auteur de nombreux ouvrages et programmes de
                formation sur l’investissement et l’entrepreneuriat
              </Explication>
            </div>
            <div>
              <Explication>
                Il s’est lancé à titre personnel sur le marché des Cryptos en 2018 avec une stratégie d’épargnant, ce qui lui a permis de
                <span className="font-semibold "> multiplier son capital par 5 en 3 ans.</span>
              </Explication>
            </div>
            <div>
              <Explication>
                Ses enseignements sont assurés au travers de <span className="font-semibold "> son organisme de formation Flow Corporation</span> (fondé en 2011) qui accompagne chaque année des
                centaines de personnes dans le développement de leurs revenus.
              </Explication>
            </div>
            <div className="hidden md:block pb-8 mt-12 hover:cursor-pointer">
              <Button text="Je veux la formation gratuite" sub="Durée 1H30" onClick={openModal} />
            </div>
          </div>
        </div>

        <div className="block md:hidden mb-8 mt-12 hover:cursor-pointer">
          <Button text="Je veux la formation gratuite" sub="Durée 1H30" onClick={openModal} />
        </div>

        <div className="text-2xl md:text-3xl font-bold mt-20">
          <div className="text-3xl font-extrabold">
            <span className="text-blue-600 "> 3 étapes</span> pour découvrir la stratégie
          </div>
          <div>Crypto Bulot</div>
        </div>

        <div className="flex flex-col md:flex-row-reverse md:items-center">
          <div className="mt-16 w-full  md:w-1/2">
            <img src={V10} className=" mx-auto  " />
          </div>

          {/* Steps CB */}
          <div className="m-0 md:m-16 w-full md:w-1/2">
            <Steps number={"1"} nbTop data-aos="fade-up" data-aos-duration="600">
              <div className="text-center md:text-justify text-lg font-light">
                Vous renseignez votre <span className="font-semibold ">prénom et adresse email </span> puis vous validez le formulaire d’inscription. Vous recevez alors les instructions par email pour
                participer à la prochaine formation qui aura lieu <span className="font-semibold ">Jeudi en live à 20h (heure de Paris) </span> avec Florent FOUQUE, le fondateur de Crypto Bulot.
              </div>
            </Steps>
            <Steps number={"2"} nbTop data-aos="fade-up" data-aos-duration="600">
              <div className="text-center md:text-justify text-lg font-light">
                <span className="font-semibold ">Jeudi à 20h, connectez-vous </span> pour participer à la formation en direct (avec le lien d’accès que vous aurez reçu au préalable par email){" "}
                <span className="font-semibold "> La formation du 1h30 avec 30 minutes des Questions Réponses </span> A la fin de la formation, vous avez le Plan d’Action pour appliquer vous-même la
                stratégie en toute autonomie. <br /> <span className="font-semibold ">Si vous n’êtes pas disponible le Jeudi à 20h, le REPLAY vous sera envoyé.</span>
              </div>
            </Steps>
            <Steps number={"3"} nbTop data-aos="fade-up" data-aos-duration="600">
              <div className="text-center md:text-justify text-lg font-light">
                Vous pouvez appliquer manuellement la stratégie en y consacrant 3-4h une fois par mois. Et vous pouvez également utiliser l’outils présenter à la fin de la formation pour appliquer la
                stratégie de façon <span className="font-semibold ">100% automatique.</span>
              </div>
            </Steps>
            <div className="mb-8 mt-12 hover:cursor-pointer" data-aos="fade-up" data-aos-duration="600">
              <Button text="Je veux la formation gratuite" sub="Durée 1H30" onClick={openModal} />
            </div>
          </div>
        </div>

        {/* Questions CB */}
        <div className="w-full md:w-3/4 md:mx-auto">
          <div className="text-2xl md:text-3xl font-bold mt-20 mb-10">
            Les questions <span className="text-blue-500"> les plus fréquentes :</span>
          </div>
          <div className="text-justify" data-aos="fade-up">
            {/* FAQ 1 */}
            <div>
              <Accordion title={"Le marché est en baisse, est-ce que bon moment pour investir dans les cryptos ?"}>
                <div className="px-4">
                  Oui c’est justement parce que le marché a fortement chuté depuis plusieurs mois que c’est un bon moment pour se lancer. La stratégie proposée est une stratégie de placement sur le
                  long terme. Les fluctuations épisodiques que nous pouvons subir par moment (y compris quand elles sont de l’ordre de -50% à -75%) sont faibles au regard des promesses de gains sur le
                  long terme (2-3 ans minimum). Ce phénomène vous sera expliqué plus en détail lors de la formation en live.
                </div>
              </Accordion>
            </div>
            {/* FAQ 2 */}
            <div>
              <Accordion title={"Cette stratégie est-elle risquée ? Peut-on tout perdre ?"}>
                <div className="flex flex-col space-y-4 px-4">
                  <p>
                    Cette stratégie est moins risquée que le trading et il est impossible de perdre tout le capital. <br /> <br />
                    Le fonctionnement de cette stratégie est très différent du trading.
                    <br /> Quand les traders identifient une opportunité de marché, ils se positionnent avec une petite partie de leur capital, l’objectif pour eux étant de limiter le risque de tout
                    perdre s’ils enchainent les transactions perdantes. De ce fait il est possible qu’en multipliant les transactions perdantes de réduire petit à petit le capital jusqu’à tout perdre.
                  </p>
                  <p>
                    La stratégie présentée dans la formation est très différente… Nous allons placer l’ensemble du capital dédié à la méthode. C’est ensuite l’évolution du marché qui va conditionner
                    l’évolution de la valeur de notre portefeuille. Mais même si le marché baisse, nous ne perdons pas d’argent. C’est comme lorsque vous achetez un bien immobilier : si le marché de
                    l’immobilier baisse, la valeur de votre bien baisse. Mais tant que vous ne revendez pas votre bien, vous n’avez pas perdu d’argent. Notre stratégie fonctionne de la même façon.
                    Nous allons conserver sur le long terme nos cryptos, si bien que même si notre portefeuille subit des baisses de valeur passagères, sur le long terme, nous savons que la valeur
                    remontera bien au dessus des prix d’achat du moment où nous avons investis.
                  </p>
                  <p>
                    Donc le seul risque de perdre du capital, c’est de décider trop tôt de stopper la stratégie et donc de revendre dans un lapse de temps trop court qui n’aura pas permis au marché de
                    se développer suffisamment.
                  </p>
                </div>
              </Accordion>
            </div>
            {/* FAQ 3 */}
            <div>
              <Accordion title={"Je suis totalement novice / débutant, cette stratégie est-elle faite pour moi ?"}>
                <div className="flex flex-col space-y-4 px-4">
                  <div>La particularité de cette méthode d’investissement est qu’elle est accessible aux débutants comme aux personnes qui connaissent déjà les cryptos. </div>
                  <div>
                    Les débutants pourront utiliser la stratégie sans connaissance dans le trading, ni les cryptos et sans avoir à suivre l’actualité des marchés. Seuls quelques calculs simples (à
                    réaliser 1x par mois) seront nécessaires à l’application de la méthode.{" "}
                  </div>
                  <div>
                    De leur côté, les personnes qui s’intéressent déjà aux cryptos depuis un moment pourront utiliser cette stratégie pour diversifier leurs stratégies de placement dans le cryptos.
                    Nous voyons également régulièrement des utilisateurs qui décident de basculer tout leur capital sur cette méthode pour ne plus perdre de temps à suivre l’actualité des cryptos et
                    rechercher sans cesse la prochaine cryptos du moment.{" "}
                  </div>
                </div>
              </Accordion>
            </div>
            {/* FAQ 4 */}
            <div>
              <Accordion title={"De quel capital minimum ai-je besoin pour appliquer cette Stratégie ?"}>
                <div className="flex flex-col space-y-4 px-4">
                  Nous préconisons un capital minimum de 3000€ pour profiter de toute la puissance de cette stratégie. Mais il est possible de l'appliquer à partir de 500€. De façon général, cette
                  stratégie peut s'adapter à n'importe quel niveau de capital. Nos utilisateurs ont investi entre 500€ et 50000€ sur cette stratégie avec une moyenne à 4500€ de capital investi.
                </div>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="mb-8 mt-12 hover:cursor-pointer" data-aos="fade-up" data-aos-duration="300">
          <Button text="Je veux la formation gratuite" sub="Durée 1H30" onClick={openModal} />
        </div>

        {/* Mention Legales */}
        <div className="flex flex-col w-full md:w-3/4 md:mx-auto space-y-6 text-xs font-light px-4 text-center ">
          <div className="font-bold">MENTIONS LEGALES</div>
          <div>Nom de la société : Flow Corporation (Créée en 2017 pour une continuité de l’activité de Flow Editions créée en France en Juin 2011)</div>
          <div>Capital de 90.000€</div>
          <div>Directeur Général : Florent FOUQUE</div>
          <div>La société se situe à l’ile Maurice à l’adresse Lot 41 Morcellement du Vieux Moulin 30546 PEREYBERE</div>
          <div>La société Flow Corporation est immatriculée au Registre du commerce sous le numéro C17148531</div>
          <div className="text-justify">
            Sur ce site Internet, toute information et donnée relative aux cryptomonnaies est fournie à titre indicatif, même si elle a été établie à partir de sources sérieuses et réputées fiables.
            Elle ne saurait constituer de la part de Flow Corporation ni une offre d’achat, de vente d’actifs numériques ni une offre de services sur actifs numériques et ne peut être assimilée à un
            conseil, une incitation ou une recommandation à opérer sur les actifs numériques visés. Les performances passées ne préjugent pas des performances futures. L’investissement dans les actifs
            numériques présente un risque de perte en capital totale ou partielle.
          </div>
          <div className="text-justify">
            AVERTISSEMENT: Veuillez noter que les résultats partagés dans cette série de vidéos sont atypiques et résultent souvent d’une expérience acquise par l’apprentissage de stratégies
            gagnantes. En vous inscrivant, vous acceptez recevoir des messages commerciaux mais surtout de recevoir régulièrement des conseils totalement gratuits. Si vous n’acceptez pas cela, ce
            n’est pas fait pour vous. Ce site ne fait pas partie du site YouTube™, Google™, Facebook™, Google Inc. ou Facebook Inc. De plus, ce site n’est PAS approuvé par YouTube™, Google™ ou
            Facebook™ en aucune façon. FACEBOOK™ est une marque de commerce de FACEBOOK, Inc. GOOGLE™ et YOUTUBE™ sont des marques de commerce de GOOGLE Inc. © Copyright – 2016-2026 Tous droits
            réservés • par Flow Corporation
          </div>
          <div className="pb-8">
            Copyright © Crypto Bulot 2022 Tous droits réservés. |{" "}
            <a href="https://bdc.crypto-bulot.com/cgv-crypto-bulot-v2/" target={"_blank"} className="text-blue-600 font-bold">
              CGV
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
