import React from "react";

function Steps(props) {
  const { number, children, hasLigne, nbTop, ...other } = props;
  return (
    <div className={`flex flex-col ${nbTop ? "" : "md:flex-row"} md:pl-4 mt-6`} {...other}>
      <div className={`text-6xl text-blue-500 font-extrabold ${nbTop ? "text-center md:text-left" : "text-center"} `}>{number}</div>
      <div>
        <div className={`p-0  ${nbTop ? "" : " md:mt-6 md:px-6"} `}>{children}</div>
        {hasLigne ? <div className="bg-slate-100 h-[2px] mt-6" /> : null}
      </div>
    </div>
  );
}

export default Steps;
