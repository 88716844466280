import React, { useEffect } from 'react';

const TrustBoxWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className="trustpilot-widget"
            data-locale="fr-FR"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="62206f077f54644e24d3bc8e"
            data-style-height="150px"
            data-style-width="100%"
            data-theme="light">
            <a href="https://fr.trustpilot.com/review/crypto-bulot.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    );
};

export default TrustBoxWidget;
